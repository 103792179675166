import React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const SneakerListing = (props) => (
  <Grid item xs={12} sm={6} >
<Card
  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
>
  <CardMedia
    component="img"
    sx={{
      // 16:9
      pt: '2.25%',

    }}
    image={props.img}
    alt={props.img_alt}
  />
  <CardContent sx={{ flexGrow: 1 }}>
    <Typography gutterBottom variant="h5" component="h2">
      {props.title}
    </Typography>
    <Typography sx={{ maxHeight:'90%'}}>
      {props.description}
    </Typography>
    <Typography sx={{ maxHeight:'90%'}}>
      {props.description_1}
    </Typography>
    <Typography sx={{ maxHeight:'90%'}}>
      {props.description_2}
    </Typography>
    <Typography sx={{ maxHeight:'90%'}}>
      {props.description_3}
    </Typography>
    <Typography sx={{ maxHeight:'90%'}}>
      {props.description_4}
    </Typography>
    <Typography sx={{ maxHeight:'90%'}}>
      {props.description_5}
    </Typography>
  </CardContent>
  <CardActions>
    <Button size="medium" href={props.link} >Learn More about the {props.title}</Button>
  </CardActions>
</Card>
</Grid>
);

export default SneakerListing;
