import React from 'react';
import {Radio, RadioGroup, FormControlLabel } from '@mui/material';

const MeasureUnits = (props) => (
  <RadioGroup
  row
    aria-labelledby="radio-measure-units-group-label"
    defaultValue="female"
    name="radio-buttons-group"
    size="small"
  >
    <FormControlLabel value="mi" control={<Radio />} label="min/mi" checked={props.measureUnitsSelected === 'mi'} onChange={props.onMeasureUnitsChange} />
    <FormControlLabel value="km" control={<Radio />} label="min/km" checked={props.measureUnitsSelected === 'km'} onChange={props.onMeasureUnitsChange}/>
  </RadioGroup>
);

export default MeasureUnits;
