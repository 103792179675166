import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

class RunningTime extends Component {
  constructor() {
    super()
    this.state = {
      hour: 10800,
      min: 180,
      sec: 32
    }
  }

  createHourOptions = () => {
    let hourOptions = [];

    for (let i = 0; i < 11; i++) {
      hourOptions.push(<MenuItem key={i} value={i*3600}>{`${i} h`}</MenuItem>);
    }
    return hourOptions;
  }

  createMinOptions = () => {
    let minOptions = [];
    for (let i = 0; i < 60; i++) {
      minOptions.push(<MenuItem key={i} value={i*60}>{`${i} min`}</MenuItem>);
    }

    return minOptions;
  }

  createSecOptions = () => {
    let secOptions = [];
    for (let i = 0; i < 60; i++) {
      secOptions.push(<MenuItem key={i} value={i}>{`${i} sec`}</MenuItem>);
    }

    return secOptions;
  }

  handleTimeChange = (event) => {
    let newValue = Number(event.target.value);
    let time;

    if (event.target.name ==='hour-form-selectors') {
      time= newValue + this.state.min + this.state.sec;
      this.setState({hour: newValue});
    } else if (event.target.name ==='min-form-selectors'){
      time= newValue + this.state.hour + this.state.sec;
      this.setState({min: newValue});
    } else {
      time= newValue + this.state.hour + this.state.min;
      this.setState({sec: newValue});
    }

    this.props.onRunningTimeChange(time);
  }

  render() {

    let defaultHour = (this.props.runningTime !== 11011.98) ? (this.props.runningTime/3600 | 0)*3600 : this.state.hour;
    let defaultMin = (this.props.runningTime !== 11011.98) ? ((this.props.runningTime-defaultHour)/60 | 0) * 60 : this.state.min;
    let defaultSec = (this.props.runningTime !== 11011.98) ? (this.props.runningTime-(defaultMin + defaultHour)) | 0 : this.state.sec;

    return (
      <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
      <FormControl sx={{ m: 1, minWidth: 40 }}>
        <Select name="hour-form-selectors" onChange={this.handleTimeChange} value={defaultHour}>
          {this.createHourOptions()}
        </Select>
</FormControl>
        <FormControl sx={{ m: 1, minWidth: 40 }}>
        <Select name="min-form-selectors" onChange={this.handleTimeChange} value={defaultMin}>
          {this.createMinOptions()}
        </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 40 }}>
        <Select name="form-selectors" onChange={this.handleTimeChange} value={defaultSec}>
          {this.createSecOptions()}
        </Select>
        </FormControl>
        <p>so.....</p>
      </Grid>
    );
  }
}

export default RunningTime;
