import React from 'react';
import SpeedBoostShoes from './speedBoostShoes.js';
import { Routes, Route } from "react-router-dom"

function App() {

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Routes>
        <Route path="/" element={ <SpeedBoostShoes/>} />
      </Routes>
    </div>
  );


}
export default App;
