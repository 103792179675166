import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Distance = (props) => (
  <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
<Typography sx={{display:'inline'}} >
  My
</Typography>
  <FormControl sx={{m:1}} >

    <Select onChange={props.onDistanceChange} value={props.currentValue}>
      <MenuItem race="Marathon" id="marathon" value="26.219">Marathon</MenuItem>
      <MenuItem id="half-marathon" value="13.1094">Half-Marathon</MenuItem>
      <MenuItem id="15K" value="9.3206">15K</MenuItem>
      <MenuItem id="10K" value="6.2137">10K</MenuItem>
      <MenuItem id="5K" value="3.1069">5K</MenuItem>
    </Select>
  </FormControl>
  <FormControl sx={{m:1}}>
    <Select  onChange={props.onTimeFormatChange} value={props.timeFormatProvided} >
      <MenuItem id="pace" value="runningPace">Pace</MenuItem>
      <MenuItem id="time" value="runningTime" >Time</MenuItem>
    </Select>

  </FormControl>
  <Typography  sx={{display:'inline'}}>
    is
    </Typography>
  </Grid>



);

export default Distance;
