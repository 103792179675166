import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

class PercentAdjuster extends Component {
  constructor() {
    super()
    this.state = {
      percent: 4,
    }
  }

  createOptions = () => {
    let minOptions = [];
    for (let i = 0; i < 11; i++) {
      minOptions.push(<MenuItem key={i} value={i/100}>{`${i} %`}</MenuItem>);
    }
    return minOptions;
  }

  handlePercentChange = (event) => {
    let newPercent = event.target.value;
    this.props.onPercentChange(newPercent);
  }


  render() {
    let defaultPercent = this.props.percent !== 4 ? this.props.percent : this.state.percent;
    return (
      <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
      A
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select  name="form-selectors" onChange={this.handlePercentChange} value={defaultPercent}>
          {this.createOptions()}
        </Select>
      </FormControl>
      improvement
      </Grid>
    );
  }
}

export default PercentAdjuster;
