import * as React from 'react';
import  { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SneakerIcon from'./sneakerIcon.js';

import * as Calculator from './Calculator.js';
import MeasureUnits from './MeasureUnits.js';
import RunningPace from './RunningPace.js';
import RunningTime from './RunningTime.js';
import Result from './Result.js';
import Distance from './Distance.js';
import PercentAdjuster from './PercentAdjuster.js';
import SneakerListing from './SneakerListing.js';

class SpeedBoostShoes extends Component {

  constructor() {
    super()
    this.state = {
      timeFormatProvided: 'runningPace',
      distanceSelected: '26.219', //in miles
      measureUnitsSelected: 'mi',
      runningPace: 420,
      runningTime: 11011.98,
      distance: 26.219,
      percentImprovement: .04
    }
  }


  Copyright = () =>{
   return (
     <Typography variant="body2" color="text.secondary" align="center">
       {'Copyright © '}
       <Link color="inherit" href="https://www.speedboostshoes.com/">
         www.speedboostshoes.com
       </Link>{' '}
       {new Date().getFullYear()}
       {'.'}
     </Typography>
   );
  };


    handleDistanceChange = (event) => {
     console.log('handleDistanceChange');
     const {timeFormatProvided, measureUnitsSelected} = this.state;

     let distanceInMiles = event.target.value,
         distance = Calculator.getDistance(measureUnitsSelected, distanceInMiles);

     if(timeFormatProvided === 'runningTime') {
       this.updateRunningPace(distance);
     }

     this.setState({distanceSelected: distanceInMiles,
                    distance: distance
                   });
   }

    handleMeasureUnitsChange = (event) => {
     const {timeFormatProvided, distanceSelected} = this.state;

     let measureUnits = event.target.value,
         distance = Calculator.getDistance(measureUnits, distanceSelected);

     if(timeFormatProvided === 'runningTime') {
       this.updateRunningPace(distance);
     }

     this.setState({measureUnitsSelected: measureUnits,
                    distance: distance
                   });
   }
   handleTimeFormatChange = (event) => {
  this.setState({timeFormatProvided: event.target.value});
  console.log(event.target.value);
  }

  handleRunningPaceChange = (time) => {
  console.log('handleRunningPaceChange');
    let runningTime = Calculator.getRunningTime(this.state.distance, time);
    //console.log(runningTime);
    //console.log(time);
    this.setState({runningTime: runningTime,
                   runningPace: time
                  });
  }

  handlePercentChange = (percent) => {
    this.setState({percentImprovement: percent
                  });
  }

  handleRunningTimeChange = (time) => {
    let runningPace = Calculator.getRunningPace(this.state.distance, time);
    this.setState({runningPace: runningPace,
                   runningTime: time
                  });
  }

  updateRunningPace = (distance) => {
    let runningPace = Calculator.getRunningPace(distance, this.state.runningTime);
    this.setState({runningPace: runningPace});
    }


render() {
  const theme = createTheme();

  const {timeFormatProvided, measureUnitsSelected, runningPace, runningTime, percentImprovement} = this.state;

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar>
          <SneakerIcon  sx={{ mr: 2 }} />
          <Typography sx={{ typography: { sm: 'h6', xs: 'body1' } }}  color="inherit" noWrap>
            4% Improvement Running Calculator
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Running Calculator: 4% Faster Marathon Time
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Use the below calculator to see how your pace or race times improve
              by 4%. Then checkout some carbon-plated
              shoes that may just help you hit those improved times.
            </Typography>
            <Box sx={{ flexGrow: 1, border: '1px dashed grey' }} >
              <Grid container spacing={.5 } rowSpacing={0} sx={{height:'70vh'}}>
                <Grid container direction='column' alignItems="flex-end" justify="flex-end"   >
                  <Grid item  xs={8} sx={{height:'5%'}}>
                    <MeasureUnits measureUnitsSelected={measureUnitsSelected} onMeasureUnitsChange={this.handleMeasureUnitsChange}/>
                  </Grid>
                </Grid>

                <Grid item xs container direction="row"   sx={{maxHeight:'10%', minHeight:'60px', ml:1}}>
                  <Distance timeFormatProvided={this.state.timeFormatProvided} currentValue={this.state.distanceSelected} onDistanceChange={this.handleDistanceChange} onTimeFormatChange={this.handleTimeFormatChange} />
                </Grid>
                <Grid item xs={12} sx={{maxHeight:'10%', minHeight:'60px', ml:1}}>
                {timeFormatProvided === 'runningPace' ?
                  <RunningPace onRunningPaceChange={this.handleRunningPaceChange} runningPace={runningPace}/>
                 : <RunningTime onRunningTimeChange={this.handleRunningTimeChange} runningTime={runningTime}/>
                 }
                 </Grid>
                <Grid item xs={12} sx={{maxHeight:'10%', minHeight:'60px', ml:1}}>
                  <PercentAdjuster onPercentChange = {this.handlePercentChange} percent = {percentImprovement} />
                </Grid>
                <Grid item xs={12} sx={{ml:1}}>
                  <Result data={this.state}/>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        <Box sx={{bgcolor: 'background.paper', pt: 3, pb: 2}}  >
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Below you can find a list of some of the top-rated carbon-plated shoes
              on the market today:
            </Typography>
          </Container>
        </Box>

        <Container sx={{ py: 1 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={2}>
                <SneakerListing
                title="Nike Alphafly Next Percent"
                img="/imgs/alphafly_next_percent.webp"
                img_alt = "Alphafly Next Percent sneaker"
                img_caption = "Image credit: Nike"
                link = 'https://www.nike.com/t/air-zoom-alphafly-next-2-mens-road-racing-shoes-Bstm8X'
                description = ''
                description_1 = 'Are you looking to improve your running speed and wondering how the Nike Alphaflys could help? Look no further! The Nike Alphaflys are a high-performance running shoe designed to help runners achieve their fastest times yet.'
                description_2 = "One of the key features of the Alphaflys is their lightweight design. Weighing in at just over 6 ounces, these shoes won't weigh you down as you tackle your runs. This can be especially helpful for longer distances, where a heavier shoe can slow you down and drain your energy."
                description_3 = "In addition to their lightweight design, the Alphaflys also feature a unique foam compound called ZoomX. This foam is extremely responsive, helping to propel you forward with each step and giving you a springy, energetic feel. The ZoomX foam is also highly cushioned, providing a comfortable ride that can help you maintain your pace for longer periods of time."
                description_4 = "But the Alphaflys aren't just about technology and design - they also have a sleek, aerodynamic look that can help you slice through the air with ease. Whether you're training for a marathon or just looking to set a new personal best, the Nike Alphaflys can be a great addition to your running arsenal."
                description_5 = "So if you're ready to take your running to the next level, consider giving the Nike Alphaflys a try. With their lightweight design, responsive foam, and aerodynamic look, these shoes can help you achieve your fastest times yet and make a noticeable difference in your running pace."
                />

                <SneakerListing
                title="Adidas Adios Pro 3"
                img="/imgs/adidas_adios_pro_3.webp"
                img_alt = "Adidas adios pro 3 sneaker"
                img_caption = "Image credit: Addidas"
                link='https://www.adidas.com/us/adizero-adios-pro-3-shoes/GX6251.html'
                description = 'Another option to consider is the Adidas Adios Pro 3. These shoes also have a lightweight design and a durable outsole that provides excellent traction and helps to conserve energy. The Adios Pro 3 has also been tested and proven to help runners achieve a 4% improvement in their pace, making it another strong choice for those looking to improve their running times.'
                description_1 = "Ultimately, the best choice for you will depend on your individual needs and preferences. Both the Nike Alphaflys and the Adidas Adios Pro 3 are high-performance options that can help you achieve your fastest times yet, so it's worth considering both to see which one works best for you."
                />

                <SneakerListing
                title="Asics Metaspeed Sky"
                img="/imgs/asics_metaspeed_sky.webp"
                img_alt = "asics metaspeed sky sneaker"
                img_caption = "Image credit: Asics"
                link = 'https://www.runningwarehouse.com/ASICS_METASPEED_Sky/descpage-AMSS2U1.html'
                description = "As a tall marathon runner, finding a running shoe that fits my needs can be a bit of a challenge. I need something that's lightweight, responsive, and comfortable enough to handle the long distances I run, but that's also sturdy enough to support my larger frame. That's why I've been loving the Asics Metaspeed Sky."
                description_1 = "One of the things I love most about the Metaspeed Sky is its lightweight design. At just under 8 ounces, these shoes won't weigh me down as I tackle my runs, which is especially important for longer distances. The Metaspeed Sky also has a unique outsole design called FlyteFoam Propel, which is highly responsive and helps to give me a springy, energetic feel as I run."
                description_2 = "But the Metaspeed Sky isn't just about technology and design - it's also built with tall runners like me in mind. The shoe has a wider toe box and a higher collar, which helps to provide a secure and comfortable fit that accommodates my longer legs and larger feet."
                description_3 = "All in all, the Asics Metaspeed Sky is a fantastic running shoe that's perfect for tall marathon runners like myself. It's lightweight, responsive, and built with my needs in mind, making it a great choice for anyone looking to take their running to the next level."
                />

                <SneakerListing
                title="Saucony Endorphin Pro 3"
                img="/imgs/saucony_ENDORPHIN_PRO_3.jpeg"
                img_alt = "saucony endorphin pro 3 sneaker"
                img_caption = "Image credit: Saucony"
                link = 'https://www.runningwarehouse.com/Saucony_Endorphin_Pro_3/descpage-SEP3M04.html'
                description = "The Saucony Endorphin Pro 3 falls somewhere in between
        the Nike and Adidas shoes in terms of price, coming in at $225 a pair. This shoe
        features a full length S-shaped carbon fiber plate that is meant to act as
        both a propulsion lever and roller to help speed up transitions."
        description_1 = " The shoe has a wider toe box and a higher collar, which helps to provide a secure and comfortable fit that accommodates my longer legs and larger feet. So what sets the Endorphin Pro 3 apart from other running shoes on the market? In my opinion, it's the combination of its lightweight design, responsive outsole, and tall runner-friendly fit that make it stand out. Whether you're training for a marathon or just looking to set a new personal best, the Saucony Endorphin Pro 3 is a great choice for tall runners looking to take their running to the next level."
                />

                <SneakerListing
                title="Nike Vaporfly Next Percent 2"
                img="/imgs/vaporfly_next_percent.webp"
                img_alt = "nike vaporfly next percent sneaker"
                img_caption = "Image credit: Nike"
                link = 'https://www.runningwarehouse.com/Nike_ZoomX_Vaporfly_Next_2/descpage-NMNEXTD.html'
                description = "Not to be confused with the Alphafly, the Vaporfly Next Percent 2s
        are another excellent option from Nike. At $250, they are $25 less than the Alphaflys and
        honestly, picking between the two may have to come down to how the shoe feels on your foot.
        Having tried both, each offers a similar level of spring and sense of propulsion while also
        providing great cushioning. I found the Alphaflys to be maybe slightly more cushioned but also
        slightly heavier feeling (and much louder too!). These are great for 5k races and up but again,
        save them for race day as much as possible to preserve their pop!"
                />


          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          SpeedBoostShoes.com
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Imagine your new speed!
        </Typography>
        {this.Copyright()}
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
}
export default SpeedBoostShoes;
