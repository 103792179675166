import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

class RunningPace extends Component {
  constructor() {
    super()
    this.state = {
      min: 420,
      sec: 0
    }
  }

  createMinOptions = () => {
    let minOptions = [];
    for (let i = 0; i < 31; i++) {
      minOptions.push(<MenuItem key={i} value={i*60}>{`${i} min`}</MenuItem>);
    }

    return minOptions;
  }

  createSecOptions = () => {
    let secOptions = [];
    for (let i = 0; i < 60; i++) {
      secOptions.push(<MenuItem key={i} value={i}>{`${i} sec`}</MenuItem>);
    }

    return secOptions;
  }

  handleTimeChange = (event) => {
    let newValue = Number(event.target.value);
    let time;
    if (event.target.name ==='min-form-selectors') {
      time= newValue + this.state.sec;
      this.setState({min: newValue});
    } else {
      time= newValue + this.state.min;
      this.setState({sec: newValue});
    }
    this.props.onRunningPaceChange(time);
  }

  render() {
    let defaultMin = (this.props.runningPace !== 420) ? (this.props.runningPace/60 | 0) * 60  : this.state.min;
    let defaultSec = (this.props.runningPace-defaultMin) | 0;

    return (
      <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select name ='min-form-selectors'  onChange={this.handleTimeChange} value={defaultMin}>
          {this.createMinOptions()}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select onChange={this.handleTimeChange} value={defaultSec}>
          {this.createSecOptions()}
        </Select>
      </FormControl>

      <Typography sx={{display:'inline'}} >
        so.....
      </Typography>

      </Grid>
    );
  }
}

export default RunningPace;
